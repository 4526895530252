import { DOMAIN_BRIDG, DOMAIN_NIMBL, ROUTES } from '@constants/constants';
import { Locale } from '@type-declarations/locale';
import {
  CasePage,
  EventPage,
  NewsPage,
  VacancyPage,
} from '@type-declarations/page';
import { Article, Event, JobPosting, Organization } from 'schema-dts';

export function getArticleStructuredData(page: NewsPage | CasePage): Article {
  return {
    '@type': 'Article',
    headline: page.title,
    image: page.hero?.image?.url,
    datePublished: page.publishOn,
    dateModified: page.lastPublishedOn,
  };
}

export function getOrganisationStructuredData(domain: string): Organization {
  if (domain === DOMAIN_BRIDG) {
    return {
      '@type': 'Organization',
      name: 'Bridg',
      url: 'https://www.bridg-software.eu',
      logo: 'https://www.bridg-software.eu/img/logo-bridg.png',
      telephone: '+32 3 860 40 00',
      email: 'info@anchrsoftware.com',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Molenweg 107',
        addressLocality: 'Willebroek',
        addressCountry: 'BE',
        postalCode: '2830',
      },
    };
  }

  if (domain === DOMAIN_NIMBL) {
    return {
      '@type': 'Organization',
      name: 'Nimbl',
      url: 'https://www.nimbl.software',
      logo: 'https://www.nimbl.software/img/logo-nimbl.png',
      telephone: '+32 3 860 40 00',
      email: 'info@anchrsoftware.com',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'Stadionstraat 27',
        addressLocality: 'Breda',
        addressCountry: 'NL',
        postalCode: '4815 NC',
      },
    };
  }

  return {
    '@type': 'Organization',
    name: 'Anchr',
    url: 'https://www.anchrsoftware.com',
    logo: 'https://www.anchrsoftware.com/img/logo.png',
    telephone: '+32 3 860 40 00',
    email: 'info@anchrsoftware.com',
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'Stadionstraat 27',
      addressLocality: 'Breda',
      addressCountry: 'NL',
      postalCode: '4815 NC',
    },
  };
}

export function getEventStructuredData(page: EventPage, locale: Locale): Event {
  const data: Event = {
    '@type': 'Event',
    name: page.title,
    description: page.hero?.text,
    startDate: page.startDate,
    endDate: page.endDate,
    eventAttendanceMode: page.online
      ? 'OnlineEventAttendanceMode'
      : 'MixedEventAttendanceMode',
    eventStatus: 'EventScheduled',
    offers: {
      '@type': 'Offer',
      price: page.price,
      url: `${process.env.ENVIROMENT_URL + ROUTES.EventsOverviewPage[locale]}/${
        page.slug
      }`,
    },
    organizer: {
      '@type': 'Organization',
      name: 'Anchr',
      url: process.env.ENVIRONMENT_URL_ANCHR,
    },
    image: page.hero?.image?.url,
    inLanguage: page.language,
  };

  if (page.location) {
    data.location = {
      '@type': 'Place',
      name: page.location.title,
      address: {
        '@type': 'PostalAddress',
        streetAddress: page.location.street,
        addressLocality: page.location.city,
        addressCountry: page.location.country,
        postalCode: page.location.zipcode,
      },
    };
  }

  if (page.targetAudience) {
    data.audience = {
      '@type': 'Audience',
      audienceType: page.targetAudience,
    };
  }

  return data;
}

export function getJobPostingStructuredData(
  page: VacancyPage,
  domain: string,
): JobPosting {
  const data: JobPosting = {
    '@type': 'JobPosting',
    title: page.title,
    description: page.overviewInfo.text,
    identifier: {
      '@type': 'PropertyValue',
      name: 'ID',
      value: page.id,
    },
    datePosted: page.publishOn,
    jobLocation: {
      '@type': 'Place',
      address: page.location,
    },
    hiringOrganization: getOrganisationStructuredData(domain),
  };

  if (page.minSalary) {
    data.baseSalary = {
      '@type': 'MonetaryAmount',
      currency: 'EUR',
      value: page.minSalary,
    };
  }

  return data;
}
